import 'airbnb-browser-shims/browser-only';
import './polyfills/formData';
import './components/Mainnav';
import './components/Favitem';
import './components/Popup';
import './components/ButtonFavlist';
import './components/PopupForm';
import './components/NewTranslationForm';
import './components/EditTranslation';
import './components/TranslationFiles';
import './components/Translation';
import './components/ResetPasswordForm';
import './components/FooterButtonBackToTop';
import './components/Clouds';
import './components/FormAccessibility';

const hash = window.location.hash.substr(1);
if (hash) {
  const hashElement = document.querySelector(`[data-id="${hash}"]`);
  if (hashElement && [...document.querySelectorAll('.translation')].length !== 1) {
    setTimeout(() => {
      if (document.scrollingElement.scrollTop === 0) {
        hashElement.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 500);
  }
}

const textareaAutosizeElements = document.querySelectorAll(".form__textarea-autosize");

textareaAutosizeElements.forEach((grower) => {
  const textarea = grower.querySelector("textarea");
  textarea.addEventListener("input", () => {
    grower.dataset.replicatedValue = textarea.value;
  });
});
