class FormAccessibility {
  constructor(element) {
    this.element = element;
    this.errorElement = element.querySelector('.form__error');
    this.successElement = element.querySelector('.form__success');
    this.submitElement = element.querySelector('.button-submit');

    element.addEventListener('submit', (event) => {
      event.preventDefault();
      this.submit();
    });
  }

  submit() {
    const formData = new FormData(this.element);

    // Add a lot of browser data
    formData.append('screen_size', `${window.innerWidth}x${window.innerHeight}`);
    formData.append('user_agent', navigator.userAgent);
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    formData.append('dark_mode', darkModeQuery.matches ? 'yes' : 'no');
    const highContrastQuery = window.matchMedia('(forced-colors: active)');
    formData.append('high_contrast', highContrastQuery.matches ? 'yes' : 'no');
    const invertedColorsQuery = window.matchMedia('(inverted-colors: invert)');
    formData.append('inverted_colors', invertedColorsQuery.matches ? 'yes' : 'no');
    const reducedMotionQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    formData.append('reduced_motion', reducedMotionQuery.matches ? 'yes' : 'no');
    const reducedTransparencyQuery = window.matchMedia('(prefers-reduced-transparency: reduce)');
    formData.append('reduced_transparency', reducedTransparencyQuery.matches ? 'yes' : 'no');
    const pointerQuery = window.matchMedia('(pointer: coarse)');
    formData.append('pointer_type', pointerQuery.matches ? 'coarse' : 'fine');
    const hoverQuery = window.matchMedia('(hover: hover)');
    formData.append('hover_capability', hoverQuery.matches ? 'yes' : 'no');
    const anyPointerQuery = window.matchMedia('(any-pointer: coarse)');
    formData.append('any_pointer', anyPointerQuery.matches ? 'coarse' : 'none');
    const anyHoverQuery = window.matchMedia('(any-hover: hover)');
    formData.append('any_hover', anyHoverQuery.matches ? 'yes' : 'no');
    formData.append('system_language', navigator.language);
    formData.append('root_font_size', getComputedStyle(document.documentElement).fontSize);
    formData.append('device_pixel_ratio', window.devicePixelRatio);
    formData.append('color_depth', window.screen.colorDepth);
    const orientationQuery = window.matchMedia('(orientation: portrait)');
    formData.append('orientation', orientationQuery.matches ? 'portrait' : 'landscape');
    formData.append('screen_resolution', `${window.screen.width}x${window.screen.height}`);

    this.submitElement.classList.add('-is-loading');

    // Clear any existing messages
    this.clearError();

    const formElements = this.element.querySelectorAll('input, textarea, button');
    formElements.forEach(element => {
      element.disabled = true;
    });
    
    fetch(this.element.action, {
      method: this.element.method,
      credentials: 'same-origin',
      body: formData
    })
    .then(response => {
      this.submitElement.classList.remove('-is-loading');
      if (response.ok) {
        return response.json();
      }
      throw new Error(`Fehler: ${response.statusText}`);
    })
    .then(data => {
      if (data.success) {
        this.showSuccess(data.message);
        this.submitElement.hidden = true;
      } else {
        this.showError(data?.message ?? 'Fataler Fehler');
        formElements.forEach(element => {
          element.disabled = false;
        });  
      }
    })
    .catch(error => {
      this.showError(error.message);
      console.warn(error);
      formElements.forEach(element => {
        element.disabled = false;
      });
    })
  }

  showError(message) {
    this.errorElement.textContent = message;
    this.errorElement.setAttribute('aria-hidden', 'false');
  }

  clearError() {
    this.errorElement.textContent = '';
    this.errorElement.setAttribute('aria-hidden', 'true');
  }

  showSuccess(message) {
    this.successElement.innerHTML = message;
    this.successElement.setAttribute('aria-hidden', 'false');
  }
}

[...document.querySelectorAll('.form-accessibility')].forEach((button) => new FormAccessibility(button));
